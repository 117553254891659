
import {
  computed,
  defineComponent, onMounted, reactive,
} from 'vue';
import { Worker } from '@/types/Ugnstno';
import api from '@/api';

export default defineComponent({
  props: ['ugnsId', 'isAdmin'],
  setup(props) {
    const isAdmin = computed(() => props.isAdmin);
    const ugnsId = computed(() => props.ugnsId);

    const cols = [
      {
        label: 'ФИО',
        // field: 'fullname',
        // width: '30%',
        component: (row: Worker) => ({
          name: 'router-link',
          class: 'link',
          value: `${row.lastName || ''} ${row.firstName || ''} ${row.middleName || ''}`,
          to: { name: isAdmin.value ? 'registry-worker-id' : 'tno-dashboard-worker-id', params: { workerId: row.id } },
        }),
      },
      {
        label: 'Должность',
        field: 'position',
      },
      {
        label: 'Телефон',
        field: 'phone',
      },
      {
        label: 'Торговые объекты',
        field: 'tradeObjectsCount',
        // display: (row: Record<string, unknown>) => '-',
      },
    ];

    const workers = reactive({
      rows: [] as (Worker)[],
      fetching: false,
      async fetch() {
        try {
          this.fetching = true;
          const res = (await api.ugnstno.getWorkersById(ugnsId.value)).data;
          this.rows = res.map(({ userProfile, tradeObjectsCount }) => ({
            ...userProfile,
            tradeObjectsCount,
            position: userProfile.position || 'Не задано',
            ugns: userProfile,
          }));
        } catch (e) {
          console.log(e);
        } finally {
          this.fetching = false;
        }
      },
    });

    onMounted(() => {
      workers.fetch();
    });

    return {
      cols,
      workers,
    };
  },
});
