
/* eslint-disable no-use-before-define */
import {
  computed,
  defineComponent, onMounted, reactive, watch,
} from 'vue';
import api from '@/api';
import usePagination from '@/modules/usePagination';
import { useStore } from 'vuex';
import { NalogPayer } from '@/types/NalogPayer';
import { TradeObjectPreview } from '@/types/ObjectOfTrade';
import { ObjectStatus } from '@/constants/objectStatuses';
import { badgeProps } from '@/utils';
import debounce from 'lodash.debounce';
import { Worker } from '@/types/Ugnstno';

export default defineComponent({
  props: ['ugnsId', 'isAdmin'],
  setup(props) {
    const store = useStore();
    const isRoleAdmin = store.state.auth.isAdmin;
    const ugnsId = computed(() => props.ugnsId);
    const changeStatusTaxPayer = {
      component: (row: TradeObjectPreview) => ({
        name: 'ChangeStatus',
        ...badgeProps(row.objectStatus),
        'onUpdate:status': async (objectStatus: string) => {
          await api.taxPayers.saveById(row.id, { objectStatus });
          nalogPayers.pagination.reset();
          await nalogPayers.fetch();
        },
      }),
    };
    const cols = [
      isRoleAdmin === true ? changeStatusTaxPayer : '',
      {
        label: 'ИНН',
        // width: '30%',
        component: (row: NalogPayer) => ({
          name: 'router-link',
          class: 'link',
          value: row.inn,
          to: { name: 'registry-nalogpayers-object-id', params: { payerId: row.id } },
        }),
      },
      {
        label: 'Юридическое наименование',
        // width: '30%',
        display: (row: NalogPayer) => row.legalName,
      },
      {
        label: 'Торговое наименование',
        // width: '30%',
        display: (row: NalogPayer) => row.tradeName,
      },
      {
        label: 'Код УГНС',
        // width: '30%',
        display: (row: NalogPayer) => row.ugnsCode,
      },
      {
        label: 'Адрес',
        // width: '30%',
        display: (row: NalogPayer) => row.address,
      },
      {
        label: 'Инспектор',
        display: (row: NalogPayer) => row.inspector || 'Не определен',
      },
    ];
    interface ExtraProps {
      selected: TradeObjectPreview[]
      assigning: boolean
      onAssign(): void
    }

    const nalogPayers = usePagination({
      filters: {
        filter: '',
        ugnsTno: computed(() => props.ugnsId),
        status: {} as ObjectStatus,
        search: '',
      },
      immediateFilters: ['status'],
      delayedFilters: ['filter'],
      rows: [] as NalogPayer[],
      selected: [],
      assigning: false,
      async onAssign() {
        try {
          nalogPayers.assigning = true;
          const id = workers.selected?.id || 0;
          const body = { nalogPayers: (nalogPayers.selected as ExtraProps['selected']).map((item) => item.id) };
          await api.taxPayers.assignWorkerToTaxPayer(id, body.nalogPayers);
          // eslint-disable-next-line no-alert
          alert('Инспектор успешно назначен');
          nalogPayers.selected = [];
          nalogPayers.pagination.reset();
          await nalogPayers.fetch();
        } catch (e) {
          // eslint-disable-next-line no-alert
          alert('Ошибка при назначении инспектора');
        } finally {
          this.assigning = false;
        }
      },
      async fetchContents() {
        const {
          filter, ugnsTno, status, search,
        } = this.filters as { filter: string, ugnsTno: string, status: ObjectStatus, search: string };
        const statusValue = status?.value;
        const params = {
          ...filter && { filter },
          ...ugnsTno && { ugnsTno },
          ...(typeof statusValue === 'string') && { status: statusValue },
          ...(typeof search === 'string') && { filter: search },
          ...nalogPayers.pagination.params,
          duplicate: store.state.auth.isAdmin,
          pageSize: 100,
        };
        return (await api.taxPayers.getAll(params)).data;
      },
    });
    const workers = reactive({
      fetching: false,
      rows: [] as (Worker | { name: 'Не выбрано' | 'Инспектор не назначен', value: null | 0, })[],
      selected: {} as Partial<Worker>,
      async fetch() {
        try {
          this.fetching = true;
          if (!ugnsId.value) {
            workers.rows = [
              { name: 'Не выбрано', value: null },
            ];
            return;
          }
          const res = (await api.ugnstno.getWorkersById(ugnsId.value)).data;
          workers.rows = [
            { name: 'Не выбрано', value: null },
            ...res.map(({ userProfile }) => ({
              ...userProfile,
              name: `${userProfile.lastName || ''} ${userProfile.firstName || ''} ${userProfile.middleName || ''}`,
              value: userProfile.id,
            }))];
        } catch (e) {
          console.log(e);
        } finally {
          this.fetching = false;
        }
      },
    });

    const paginateHandler = (pageNumber: number) => {
      nalogPayers.pagination.page = pageNumber - 1;
      nalogPayers.fetch(false, true);
    };

    const pagination = reactive({
      pages: computed(() => nalogPayers.pagination.totalPages),
      currentPage: computed(() => nalogPayers.pagination.page + 1),
      fetching: computed(() => nalogPayers.fetching),
    });

    onMounted(() => {
      workers.fetch();
      nalogPayers.fetch();
    });

    const searchHint = reactive({
      display: false,
      rows: [] as string[],
    });

    const delayedGetSuggestions = debounce(async () => {
      let request: any = nalogPayers.filters.search;
      request = request.toLowerCase().includes('киргизия') ? request : `киргизия ${request}`;
      const obj: any = await (window.ymaps as any).suggest(request);
      searchHint.rows = obj.map((item: any) => ({ name: item.displayName, value: item.value }));
    }, 500);
    watch(
      () => [nalogPayers.filters.search],
      () => {
        delayedGetSuggestions();
        nalogPayers.fetch();
      },
    );

    return {
      workers,
      searchHint,
      nalogPayers,
      cols,
      paginateHandler,
      pagination,
    };
  },
});
