import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createBlock(_component_UiTable, {
    cols: _ctx.cols,
    rows: _ctx.workers.rows,
    emptyBannerText: _ctx.workers.fetching ? 'Идет загрузка' : 'Сотрудники отсутствуют'
  }, null, 8, ["cols", "rows", "emptyBannerText"]))
}