
/* eslint-disable no-use-before-define */
import {
  computed,
  defineComponent, onMounted, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/api';
import {
  TradeObjectPreview,
} from '@/types/ObjectOfTrade';
import { Worker } from '@/types/Ugnstno';
import ContainerHeader from '@/components/ContainerHeader.vue';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import PageControls from '@/components/PageControls.vue';
import { ObjectStatus } from '@/constants/objectStatuses';
import ViewportObserver from '@/components/ViewportObserver.vue';
import usePagination from '@/modules/usePagination';
import { badgeProps } from '@/utils';
import debounce from 'lodash.debounce';

export default defineComponent({
  components: {
    PageControls,
    ContainerHeader,
    ColorSelector,
    ViewportObserver,
  },
  props: ['ugnsId', 'isAdmin'],
  setup(props) {
    const store = useStore();
    const isAdmin = computed(() => props.isAdmin);
    const ugnsId = computed(() => props.ugnsId);
    const isRoleAdmin = store.state.auth.isAdmin;
    const changeStatusTradeObject = {
      component: (row: TradeObjectPreview) => ({
        name: 'ChangeStatus',
        ...badgeProps(row.objectStatus),
        'onUpdate:status': async (objectStatus: string) => {
          await api.objectsOfTrade.saveById(row.id, { objectStatus });
          objects.pagination.reset();
          await objects.fetch();
        },
      }),
    };
    const cols = [
      isRoleAdmin === true ? changeStatusTradeObject : '',
      {
        label: 'Адрес',
        component: (row: TradeObjectPreview) => ({
          name: 'router-link',
          class: 'link',
          value: row.yandexAddressFull || row.userInput,
          to: { name: isAdmin.value ? 'registry-trade-objects-object-id' : 'tno-dashboard-object-id', params: { objectId: row.id } },
        }),
      },
      {
        label: 'ИНСПЕКТОР',
        display: (row: TradeObjectPreview) => {
          const { firstName = '', lastName = '', middleName = '' } = row?.objectWorker || {};
          return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
        },
      },
      {
        label: 'тип помещения',
        display: (row: TradeObjectPreview) => row.buildingType || 'Не определено',
      },
      {
        label: 'Кол-во НП',
        display: (row: TradeObjectPreview) => row.nalogPayerCount,
      },
    ];

    interface CurrentFilters extends Record<string, unknown> {
      worker: Worker
      address: string
      status: ObjectStatus
    }
    interface ExtraProps {
      selected: TradeObjectPreview[]
      assigning: boolean
      onAssign(): void
    }
    const objects = usePagination({
      filters: {
        worker: {},
        address: '',
        status: {},
      } as CurrentFilters,
      immediateFilters: ['status', 'worker'],
      delayedFilters: ['address'],
      rows: [] as TradeObjectPreview[],
      selected: [],
      assigning: false,
      async onAssign() {
        try {
          objects.assigning = true;
          const id = workers.selected?.id || 0;
          const body = { tradeobjects: (objects.selected as ExtraProps['selected']).map((item) => item.id) };
          await api.objectsOfTrade.assignWorkerToObject(id, body);
          // eslint-disable-next-line no-alert
          alert('Инспектор успешно назначен');
          objects.selected = [];
          objects.pagination.reset();
          await objects.fetch();
        } catch (e) {
          // eslint-disable-next-line no-alert
          alert('Ошибка при назначении инспектора');
        } finally {
          this.assigning = false;
        }
      },
      async fetchContents() {
        const filters = objects.filters as CurrentFilters;
        const { address } = filters;
        const status = filters.status?.value ? `${filters.status?.value}` : null;
        const worker = filters.worker?.id;
        const withDuplicates = store.state.auth.isAdmin;

        const params = {
          ...!!address && { address },
          ...status && { status },
          ...(worker || worker === 0) && { worker },
          ...objects.pagination.params,
          duplicate: withDuplicates,
        };

        return (await api.objectsOfTrade.getByUgnsId(ugnsId.value, params)).data;
      },
    });

    const workers = reactive({
      fetching: false,
      rows: [] as (Worker | { name: 'Не выбрано' | 'Инспектор не назначен', value: null | 0, })[],
      selected: {} as Partial<Worker>,
      async fetch() {
        try {
          this.fetching = true;
          if (!ugnsId.value) {
            workers.rows = [
              { name: 'Не выбрано', value: null },
            ];
            return;
          }
          const res = (await api.ugnstno.getWorkersById(ugnsId.value)).data;
          workers.rows = [
            { name: 'Не выбрано', value: null },
            ...res.map(({ userProfile }) => ({
              ...userProfile,
              name: `${userProfile.lastName || ''} ${userProfile.firstName || ''} ${userProfile.middleName || ''}`,
              value: userProfile.id,
            }))];
        } catch (e) {
          console.log(e);
        } finally {
          this.fetching = false;
        }
      },
    });
    const workersFilterOptions = computed(() => {
      const arr = workers.rows.slice();
      arr.splice(1, 0, { name: 'Инспектор не назначен', value: 0, id: 0 });
      return arr;
    });

    onMounted(() => {
      workers.fetch();
      objects.fetch();
    });

    const searchHints = reactive({
      display: false,
      rows: [] as string[],
    });

    const delayedGetSuggestions = debounce(async () => {
      let request: any = objects.filters.address;
      request = request.toLowerCase().includes('киргизия') ? request : `киргизия ${request}`;
      const obj: any = await (window.ymaps as any).suggest(request);
      searchHints.rows = obj.map((item: any) => ({ name: item.displayName, value: item.value }));
    }, 500);
    watch(
      () => [objects.filters.address],
      () => {
        delayedGetSuggestions();
      },
    );

    return {
      workers,
      workersFilterOptions,
      cols,
      objects,
      searchHints,
    };
  },
});
