
import { useRouter, useRoute } from 'vue-router';
import {
  computed,
  defineComponent, onMounted, reactive, ref, watch,
} from 'vue';
import MainLayout from '@/layout/MainLayout.vue';
import FlatTabs from '@/components/UI/FlatTabs/index.vue';
import Container from '@/components/Container.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import store from '@/store';
import api from '@/api';
import { StatusStat, TnoStat } from '@/types/Statistic';
import PageControls from '@/components/PageControls.vue';
import UploadNewUsers from '@/components/Modals/UploadNewUsers.vue';
import UploadNewItems from '@/components/Modals/uploadNewItems.vue';
import WorkersTab from './components/WorkersTab.vue';
import ObjectsTab from './components/ObjectsTab.vue';
import NalogPayerTab from './components/NalogPayerTab.vue';
/**
 * Страница используется в двух роутах, вслучае где админ смотрит УГНС, и в случае где ЦА смотрит
 */
export default defineComponent({
  name: 'Home',
  components: {
    MainLayout, Container, FlatTabs, ContainerHeader, WorkersTab, ObjectsTab, NalogPayerTab, PageControls, UploadNewUsers, UploadNewItems,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const showUploadNewItems = ref(false);
    const showUploadNewUsers = ref(false);

    /**
     * Если админ просмотривает конкретную УГНС, то вернуть ugnsId из URL
     * Если ЦА просматривает, то взять УГНС из стора
     */
    // const isAdmin = computed(() => store.state.auth.userInfo?.role === 'ADMIN');
    const isAdmin = computed(() => !!+route.params.ugnsId);
    const ugnsId = computed(() => (isAdmin.value ? (+route.params.ugnsId || 0) : (store.state.auth.userInfo?.ugnsTno.id || 0)));

    const tabs = [
      {
        name: 'Сотрудники',
        id: 'WorkersTab',
      },
      {
        name: 'Объекты торговли',
        id: 'ObjectsTab',
      },
      {
        name: 'Налогоплательщики',
        id: 'NalogPayerTab',
      },
    ];

    const currentTab = ref(tabs.find(({ id }) => id === route.query.tab) || tabs[0]);
    const currentTabIndex = computed(() => tabs.findIndex((tab) => tab.id === currentTab.value.id));
    const handleUploadClick = () => {
      if (currentTabIndex.value === 0) {
        showUploadNewUsers.value = true;
      } else {
        showUploadNewItems.value = true;
      }
    };
    watch(
      () => route.query,
      async (newQuery) => {
        tabs.forEach((tab) => {
          if (tab.id === newQuery.tab) currentTab.value = tab;
        });
      },
      { immediate: true },
    );
    function tabClick($event: { name: string; id: string }) {
      router.push({ query: { tab: $event.id } });
    }

    const stats = reactive({
      statusStat: null as StatusStat | null,
      nalogPayer: null as StatusStat | null,
      values: null as TnoStat | null,
      async fetch() {
        const res = (await api.statistic.getForTnoById(`${ugnsId.value}`)).data;
        this.values = res.tnoStat;
        this.statusStat = res.statusStat;
        this.nalogPayer = res.nalogPayersStat;
      },
    });

    const info = reactive({
      name: '' as string,
      async fetchName() {
        try {
          const ugnsInfo = (await api.ugnstno.getInfoById(`${ugnsId.value}`)).data;
          this.name = `${ugnsInfo?.regionCity || ''} ${ugnsInfo?.ugnsTnoCode || ''}`;
        } catch (e) {
          console.log(e);
        }
      },
    });

    onMounted(() => {
      info.fetchName();
      stats.fetch();
    });

    return {
      stats, info, tabs, currentTab, currentTabIndex, tabClick, isAdmin, ugnsId, showUploadNewItems, showUploadNewUsers, handleUploadClick,
    };
  },
});

